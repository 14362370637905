<template>
  <SwitchWithSlot
    :value.sync="patient.nopesel"
    label="Osoba nieposiadająca numeru PESEL."
    :autosave="autosave"
    :syncId="syncId"
  >
    <v-layout column v-if="patient.nopesel">
      <DefaultSubtitle class="mx-4">Inny dokument</DefaultSubtitle>
      <v-layout :class="['mt-6', $vuetify.breakpoint.xsOnly && 'wrap']">
        <v-flex :class="size">
          <P1IDDocumentTypeSelect
            class="mr-3"
            :value.sync="patient.id_type"
            :autosave="autosave"
            :syncId="syncId"
            required
          ></P1IDDocumentTypeSelect>
        </v-flex>
        <v-flex v-if="other" :class="size">
          <LabelTextField
            :value.sync="patient.nopesel_doctype"
            label="Rodzaj dokumentu"
            :autosave="autosave"
            :syncId="syncId"
          ></LabelTextField>
        </v-flex>
        <v-flex :class="size">
          <LabelTextField
            required
            :value.sync="patient.nopesel_docnum"
            label="Numer dokumentu"
            :autosave="autosave"
            :syncId="syncId"
          ></LabelTextField>
        </v-flex>
        <v-flex v-if="child" :class="size">
          <DefaultSelect
            label="Kolejność dziecka"
            :items="childrens"
            :value.sync="patient.child_number"
            :autosave="autosave"
            :syncId="syncId"
            required
          >
          </DefaultSelect>
        </v-flex>
      </v-layout>
    </v-layout>
  </SwitchWithSlot>
</template>

<script>
import Validators from "@/mixins/Validators";
import ProxyModificationMixin from "@/mixins/ProxyModificationMixin"

export default {
  mixins: [Validators, ProxyModificationMixin],
  props: {
    patient: {},
  },
  computed: {
    childrens() {
      let items = [];
      for (var i = 1; i < 21; i++) {
        items.push({ value: i, text: i });
      }
      return items;
    },
    other() {
      return this.patient.id_type === "W";
    },
    child() {
      return this.patient.id_type == "M";
    },
    size() {
      if (this.other || this.child) {
        return "xs12 sm4";
      } else {
        return "xs12 sm6";
      }
    },
  },
  components: {
    DefaultSubtitle: () => import("@/components/text/DefaultSubtitle"),
    SwitchWithSlot: () => import("@/components/controls/SwitchWithSlot"),
    LabelTextField: () => import("@/components/LabelTextField"),
    DefaultSelect: () => import("@/components/selects/DefaultSelect"),
    P1IDDocumentTypeSelect: () =>
      import("@/components/selects/p1/P1IDDocumentTypeSelect"),
  },
};
</script>