<template>
  <v-layout column>
    <template v-for="(field, index) in textFields">
      <v-layout column :key="index">
        <v-divider v-if="index == shfsIndex - 1"></v-divider>
        <PredefinedFieldRecordsDelegate
          :color="index % 2 ? '#F9F9F9' : 'white'"
          :field="field"
          :noPairs="index <= 3"
          :cardType="cardType"
          @success="handleSuccess"
          @error="handleError"
        ></PredefinedFieldRecordsDelegate>
      </v-layout>
    </template>
    <v-layout mt-4 mb-4 mx-4>
      <v-spacer></v-spacer>
      <v-flex shrink>
        <DefaultButton
          @click.prevent="save"
          class="mx-4 px-0 mb-6"
          primary
          >Zapisz</DefaultButton
        >
      </v-flex>
    </v-layout>
  </v-layout>
</template>

<script>
import SettingsService from "@/services/settings.service";
import ProxyCRUDMixin from "@/mixins/ProxyCRUDMixin";
import DoctorReloadMixin from "@/mixins/DoctorReloadMixin";
import { FIELD_TYPES } from "@/constants/patientcard/fields/types";
import _ from "lodash";

export default {
  mixins: [ProxyCRUDMixin, DoctorReloadMixin],
  props: {
    cardType: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      fields: [],
      shfsIndex: 0,
    };
  },
  components: {
    DefaultButton: () => import("@/components/buttons/DefaultButton"),
    PredefinedFieldRecordsDelegate: () =>
      import("@/components/delegates/PredefinedFieldRecordsDelegate"),
  },
  computed: {
    textFields() {
      return _.filter(this.fields, function (element) {
        if (
          element.type === undefined ||
          element.type === FIELD_TYPES.TEXT_FIELD
        ) {
          return true;
        } else {
          return false;
        }
      });
    },
  },
  methods: {
    fetch() {
      this.beforeRequest();
      SettingsService.getPatientCardSettings(this.cardType)
        .then((x) => {
          this.shfsIndex = x.session_history_fields.length;
          this.fields = [
            ...x.session_history_fields,
            ...x.appointment_history_fields,
          ];
          this.reset();
        })
        .catch((error) => {
          if (error.response && error.response.status == 403) {
            this.fields = [];
            this.handleError("Brak dostępu.");
          } else {
            this.handleError("Coś poszło nie tak.");
          }
        });
    },
    save() {
      this.beforeRequest();
      SettingsService.updatePatientCardSettings({
        card_type: this.cardType,
        session_history_fields: this.fields.slice(0, this.shfsIndex),
        appointment_history_fields: this.fields.slice(this.shfsIndex),
      })
        .then((x) => {
          this.shfsIndex = x.session_history_fields.length;
          console.log(this.fields.slice(0, this.shfsIndex));
          this.fields = [
            ...x.session_history_fields,
            ...x.appointment_history_fields,
          ];
          this.handleSuccess("Zmiany zostały zapisane");
        })
        .catch((e) => {
          console.log(e);
          this.handleError("Nie udało się zapisać zmian.");
        });
    },
    reloadData() {
      this.fetch();
    },
  },
  mounted() {
    this.fetch();
  },
};
</script>