<template>
  <TemplateButtonWithPopup
    :fetcher="fetch"
    @selected="handleSelected"
    :block="block"
  ></TemplateButtonWithPopup>
</template>

<script>
import PredefinedService from "@/services/predefined.service";

export default {
  props: {
    field: {},
    block: {
      type: Boolean,
      default: false
    }
  },
  components: {
    TemplateButtonWithPopup: () =>
      import("@/components/controls/TemplateButtonWithPopup"),
  },
  methods: {
    handleSelected(template) {
      this.$emit("selected", template);
    },
    fetch() {
      return PredefinedService.getTemplates(this.field.name);
    },
  },
};
</script>