<template>
  <ConfirmDialog
    :maxWidth="templates.length <= 1 ? '400px' : '800px'"
    title="Dostępne szablony"
    :value.sync="show"
    positiveButtonText="Wybierz"
    :positiveAction="onSelected"
  >
    <v-layout v-if="templates.length > 0" wrap slot="content">
      <v-flex
        @click.stop="() => onClick(index)"
        v-for="(template, index) in templates"
        :key="index"
      >
        <SelectableLabelTextArea
          :selected="index == selected"
          :readOnly="true"
          :value="display(template)"
          :hideDetails="true"
        ></SelectableLabelTextArea>
      </v-flex>
    </v-layout>
    <v-layout v-else justify-center slot="content">
      <DefaultLabel>Brak szablonów</DefaultLabel>
    </v-layout>
  </ConfirmDialog>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
    },
    templates: {
      type: Array,
      default: () => [],
    },
    display: {
      type: Function,
      default: (template) => template.text,
    },
  },
  data() {
    return {
      selected: null,
    };
  },
  components: {
    ConfirmDialog: () => import("@/components/popups/ConfirmDialog"),
    SelectableLabelTextArea: () =>
      import("@/components/inputs/SelectableLabelTextArea"),
    DefaultLabel: () => import("@/components/text/DefaultLabel"),
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("update:value", value);
      },
    },
  },
  methods: {
    onClick(index) {
      this.selected = index;
    },
    onSelected() {
      this.$emit("selected", this.templates[this.selected]);
    },
  },
};
</script>