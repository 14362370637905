<template>
  <div>
    <v-btn
      small
      :class="['zg-info-button', noMargin ? 'ma-0' : 'ma-2']"
      :ripple="false"
      icon
      @click.prevent="showDialog = true"
    >
      <v-icon
        small
        color="icons"
        style="vertical-align: middle; font-size: 14px"
        >mdi-help-circle-outline</v-icon
      >
    </v-btn>
    <ConfirmDialog 
      :value.sync="showDialog" 
      :title="title" 
      positiveButtonText 
      :negativeButtonText="buttonText"
      maxWidth="500px"
    >
      <div
        class="text-subtitle-1 black--text"
        v-html="text"
        slot="content"
      ></div>
    </ConfirmDialog>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      default: "Informacja",
    },
    text: {
      default: "",
    },
    noMargin: {
      type: Boolean,
      default: false,
    },
    buttonText: {
      default: "Rozumiem"
    }
  },
  data() {
    return {
      showDialog: false,
    };
  },
  components: {
    ConfirmDialog: () => import("@/components/popups/ConfirmDialog"),
  },
};
</script>

<style lang="scss" scoped>
.zg-info-button {
  height: 22px !important;
  width: 22px !important;
}
.zg-info-button ::v-deep .v-icon {
  height: 22px !important;
  width: 22px !important;
}
</style>