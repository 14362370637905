<template>
  <v-form ref="form" lazy-validation>
    <v-layout column>
      <v-layout
        v-if="subscription.status === null"
        align-center
        column
        class="mt-4"
      >
        <v-progress-circular
          indeterminate
          color="primary"
          width="7"
          size="60"
          class="ma-3"
        ></v-progress-circular>
        <DefaultSubtitle class="ma-3">Wczytuję dane...</DefaultSubtitle>
      </v-layout>
      <template v-else-if="isSubscriptionActive">
        <v-layout align-center class="mx-4 mb-4">
          <DefaultSubtitle color="primary">Dane karty</DefaultSubtitle>
        </v-layout>
        <LabelTextField label="IMIĘ I NAZWISKO WŁAŚCICIELA KARTY" :value="card.holder" disabled>
        </LabelTextField>
        <LabelTextField label="NUMER KARTY" :value="card.number" disabled>
        </LabelTextField>
        <LabelTextField label="ADRES E-MAIL WŁAŚCICIELA BIZNESU" :value="card.email" disabled hideDetails>
        </LabelTextField>
        <DefaultButton
          v-if="isSubscriptionNotPaid"
          class="mx-4 mt-4"
          primary
          :disabled="isSubscriptionCancelled || !canSubscribe"
          :loading="loading || isPending"
          @click.prevent="pay"
          >PONÓW PŁATNOŚĆ</DefaultButton
        >
        <DefaultButton
          class="mx-4 mt-4"
          primary
          @click.prevent="
            () => {
              beforeRequest();
              const c = win.open('', '_blank');
              let cardAction = null;
              if (isSubscriptionNotPaid) {
                cardAction = sub.addCard();
              } else {
                cardAction = sub.changeCard();
              }
              
              cardAction
                .then((reply) => {
                  getCard();
                  c.location.href = reply.link;
                })
                .catch((e) => {
                  reset();
                });
            }
          "
          :disabled="isSubscriptionCancelled || !canSubscribe"
          :loading="loading || isPending"
          >{{ changeCardText }}</DefaultButton
        >
        <DefaultButton
          v-if="isPending"
          class="mx-4 mt-4"
          @click.prevent="removeCard"
          >SPRÓBUJ PONOWNIE</DefaultButton
        >
        <v-layout justify-center class="mx-4">
          <DefaultLabel v-html="legalLabel"></DefaultLabel>
        </v-layout>
        <v-layout align-center class="mx-4 mt-4">
          <DefaultSubtitle color="primary">Status subskrypcji</DefaultSubtitle>
        </v-layout>
        <DefaultLabel class="mx-4" v-html="subscriptionStatus"> </DefaultLabel>
        <DefaultLabel class="mx-4" v-html="subscriptionDetails"> </DefaultLabel>
      </template>
      <template v-else>
        <v-layout column class="mx-4 mb-4">
          <DefaultSubtitle>BooksyMed</DefaultSubtitle>
          <DefaultLabel>
            Dodaj kartę i zacznij korzystać z BooksyMed!
          </DefaultLabel>
          <DefaultSubtitle class="mt-4"
            >{{ subscription.net_amount }} zł / miesiąc</DefaultSubtitle
          >
          <DefaultButton
            class="mx-0 mt-4"
            primary
            @click.prevent="
              () => {
                beforeRequest();
                const c = win.open('', '_blank');
                sub
                  .changeCard()
                  .then((reply) => {
                    getCard();
                    c.location.href = reply.link;
                  })
                  .catch((e) => {
                    con.log('e', e);
                    reset();
                  });
              }
            "
            :loading="loading || isPending"
            :disabled="!canSubscribe"
            >DODAJ KARTĘ</DefaultButton
          >
          <DefaultButton
            v-if="isPending"
            class="mx-0 mt-4"
            @click.prevent="removeCard"
            >SPRÓBUJ PONOWNIE</DefaultButton
          >
          <v-layout justify-center class="mx-4">
            <DefaultLabel v-html="legalLabel"></DefaultLabel>
          </v-layout>
        </v-layout>
      </template>
    </v-layout>
    <CannotSubscribe :value="!canSubscribe" />
  </v-form>
</template>

<script>
import { mapActions } from "vuex";
import ProxyCRUDMixin from "@/mixins/ProxyCRUDMixin";
import SubscriptionsService from "@/services/subscriptions.service";
import { CARD_STATUS, SUBSCRIPTION_STATUS } from "@/constants/subscriptions";

export default {
  mixins: [ProxyCRUDMixin],
  data() {
    return {
      isCardAdded: null,
      card: {
        number: "",
        holder: "",
        email: "",
        status: CARD_STATUS.PENDING,
      },
      subscription: {
        status: null,
        net_amount: "1.00",
        can_subscribe: true,
        has_unpaid_invoice: false
      },
      legalLabel: `<a href="${process.env.VUE_APP_URL_BACKEND + '/static/documents/Znajdz_Gabinet_Booksy_Med_PP.pdf'}" target="_blank">Polityka Prywatności</a> & <a href="${process.env.VUE_APP_URL_BACKEND + '/static/documents/Znajdz_Gabinet_Booksy_Med_Regulamin.pdf'}" target="_blank">Regulamin</a>`,
      sub: SubscriptionsService,
      con: console,
      win: window,
    };
  },
  components: {
    DefaultButton: () => import("@/components/buttons/DefaultButton"),
    LabelTextField: () => import("@/components/LabelTextField"),
    DefaultSubtitle: () => import("@/components/text/DefaultSubtitle"),
    DefaultLabel: () => import("@/components/text/DefaultLabel"),
    InfoButton: () => import("@/components/buttons/InfoButton"),
    CannotSubscribe: () => import("@/components/popups/subscriptions/CannotSubscribe"),
  },
  computed: {
    isSubscriptionPaid() {
      return SUBSCRIPTION_STATUS.PAID === this.subscription.status;
    },
    isSubscriptionCancelled() {
      return (
        SUBSCRIPTION_STATUS.PENDING_CANCELLATION === this.subscription.status
      );
    },
    isSubscriptionNotPaid() {
      return [SUBSCRIPTION_STATUS.OVERDUE_BLOCKED, SUBSCRIPTION_STATUS.CHURNED].includes(this.subscription.status) && this.canPay;
    },
    isSubscriptionActive() {
      return [
        SUBSCRIPTION_STATUS.PAID,
        SUBSCRIPTION_STATUS.PENDING_CANCELLATION,
        SUBSCRIPTION_STATUS.OVERDUE_BLOCKED,
        SUBSCRIPTION_STATUS.CHURNED
      ].includes(this.subscription.status);
    },
    canSubscribe() {
      return this.subscription.can_subscribe;
    },
    canPay() {
      return this.subscription.has_unpaid_invoice;
    },
    isPending() {
      return this.card.status === CARD_STATUS.PENDING;
    },
    isActive() {
      return this.card.status === CARD_STATUS.ACTIVE;
    },
    changeCardText() {
      if (this.isSubscriptionNotPaid) {
        return "ZMIEŃ KARTĘ I ZAPŁAĆ";
      } else {
        return "ZMIEŃ KARTĘ";
      }
    },
    subscriptionStatus() {
      switch (this.subscription.status) {
        case SUBSCRIPTION_STATUS.PAID:
          return "Subskrypcja jest <b>aktywna</b>.";
        case SUBSCRIPTION_STATUS.PENDING_CANCELLATION:
          return "Subskrypcja jest <b>anulowana</b>.";
        case SUBSCRIPTION_STATUS.CHURNED:
        case SUBSCRIPTION_STATUS.OVERDUE_BLOCKED:
          return "Subskrypcja jest <b>nieaktywna</b>.";
        default:
          return "";
      }
    },
    subscriptionDetails() {
      switch (this.subscription.status) {
        case SUBSCRIPTION_STATUS.PAID:
          return `Następna płatność <b>${this.subscription.next_billing_date}</b>`;
        case SUBSCRIPTION_STATUS.PENDING_CANCELLATION:
          return `Subskrypcja będzie aktywna do dnia <b>${this.subscription.next_billing_date}</b>.`;
        case SUBSCRIPTION_STATUS.CHURNED:
        case SUBSCRIPTION_STATUS.OVERDUE_BLOCKED:
          return `Nie udało się opłacić subskrypcji.`;
        default:
          return "";
      }
    },
  },
  methods: {
    ...mapActions("ui", ["updateSubscriptionStatus"]),
    async getCard() {
      this.beforeRequest();
      return SubscriptionsService.getCard()
        .then((reply) => {
          this.card = reply;
          if (this.isActive) {
            this.isCardAdded = true;
            this.getSubscriptionDetails();
          } else if (this.isPending) {
            this.isCardAdded = false;
            setTimeout(this.getCard, 10000);
          }
        })
        .catch(() => {
          this.isCardAdded = false;
          this.card.status = CARD_STATUS.INACTIVE;
        })
        .finally(() => {
          this.reset();
        });
    },
    removeCard() {
      this.beforeRequest();
      return SubscriptionsService.removeCard()
        .then(() => {
          this.reset();
          this.getCard();
        })
        .catch((e) => {
          this.handleError("Coś poszło nie tak");
        });
    },
    async handleSubscriptionResult(subscription) {
      this.subscription = subscription;
      if (!this.isSubscriptionActive) {
        this.updateSubscriptionStatus(false);
      } else {
        this.updateSubscriptionStatus(true);
      }
    },
    async getSubscriptionDetails() {
      this.beforeRequest();
      return SubscriptionsService.getSubscriptionsDetails()
        .then((reply) => this.handleSubscriptionResult(reply))
        .catch((e) => {
          this.handleError("Coś poszło nie tak");
        })
        .finally(() => {
          this.reset();
        });
    },
    async pay() {
      this.beforeRequest();
      return SubscriptionsService.pay()
        .then((reply) => this.handleSubscriptionResult(reply))
        .then(() => this.handleSuccess("Subskrypcja została opłacona"))
        .catch((e) => {
          this.handleError("Coś poszło nie tak");
        })
        .finally(() => {
          this.reset();
        });
    }
  },
  async created() {
    await this.getSubscriptionDetails();
    await this.getCard();
  },
};
</script>
