<template>
  <div>
    <v-layout column>
      <v-layout column mx-4>
        <v-switch
          label="Pokazuj w karcie pacjenta"
          color="expressive_green"
          class="zg-text-control"
          v-model="cardTypeVisibleProxy"
          hide-details
          :ripple="false"
          inset
        ></v-switch>
        <v-divider class="my-4"></v-divider>
      </v-layout>
      <v-layout align-start mr-4 mb-2>
        <v-layout column>
          <LabelTextField
            label="Dodatkowe pole do karty pacjenta"
            placeholder="Dodaj nowe pole..."
            :hideDetails="true"
            :value.sync="name"
          ></LabelTextField>
          <MaxlengthErrorMessage
            class="mx-4"
            :value="name"
            :maxlength="256"
          ></MaxlengthErrorMessage>
        </v-layout>
        <DefaultButton
          class="pb-0 mb-0 mr-0 mt-0"
          :disabled="name.length > 256"
          @click.prevent="() => addDialog(add)"
          >Dodaj</DefaultButton
        >
      </v-layout>
      <v-layout
        :class="$vuetify.breakpoint.xsOnly ? 'column' : 'align-start'"
        mr-4
        my-4
      >
        <DefaultSelect
          label="Dodatkowy podpunkt do pola"
          placeholder="Wybierz nazwę pola"
          :hideDetails="true"
          :items="items"
          :itemText="(x) => label(x)"
          :itemValue="(x) => x.order"
          :value.sync="below"
        ></DefaultSelect>
        <v-layout column>
          <LabelTextField
            :value.sync="subname"
            label="Nazwa podpunktu"
            placeholder="Dodaj nowy podpunkt..."
            :hideDetails="true"
          ></LabelTextField>
          <MaxlengthErrorMessage
            class="mx-4"
            :value="subname"
            :maxlength="256"
          ></MaxlengthErrorMessage>
        </v-layout>
        <DefaultButton
          class="pb-0 mr-0 mb-0 mt-0"
          @click.prevent="() => addAltDialog(addWithBullet)"
          >Dodaj</DefaultButton
        >
      </v-layout>
      <v-layout column>
        <DefaultSubtitle class="mt-2 mb-4 ml-4"
          >Kolejność pól</DefaultSubtitle
        >
        <template v-for="(field, index) in fields">
          <PatientCardSettingsDelegate
            :upAvailable="index != 0"
            :downAvailable="index != fields.length - 1"
            :key="index"
            :value="field"
            :label="label(field)"
            @remove="(x) => deleteDialog(x)"
            @edit="() => editDialog(field, index)"
            @up="() => moveUp(index)"
            @down="() => moveDown(index)"
          ></PatientCardSettingsDelegate>
        </template>
      </v-layout>
      <v-layout mt-4>
        <v-spacer></v-spacer>
        <DefaultButton @click.prevent="save" class="mx-4 px-0 mb-6" primary
          >Zapisz</DefaultButton
        >
      </v-layout>
    </v-layout>
    <ConfirmDialog
      v-if="showDeleteDialog"
      :value.sync="showDeleteDialog"
      :positiveAction="() => remove(nameToDelete)"
      title="Usunąć pole?"
      text="Czy na pewno chcesz usunąć to pole? Tej akcji nie można cofnąć."
      positiveButtonText="Usuń pole"
      negativeButtonText="Anuluj"
    ></ConfirmDialog>
    <ConfirmDialog
      v-if="showEditDialog"
      :value.sync="showEditDialog"
      positiveButtonText="Zapisz"
      negativeButtonText="Anuluj"
      :positiveAction="() => onSave()"
      :disabledPositiveButton="
        !toEdit|| toEdit.label.length > 256
      "
      :title="editName"
    >
      <v-layout column slot="content">
        <LabelTextField
          label="Nazwa"
          :value.sync="toEdit.label"
          hideDetails
        ></LabelTextField>
        <MaxlengthErrorMessage
          class="mx-4"
          :value="toEdit.label"
          :maxlength="256"
        ></MaxlengthErrorMessage>
      </v-layout>
    </ConfirmDialog>
    <ConfirmDialog
      v-if="showAddDialog"
      :value.sync="showAddDialog"
      positiveButtonText="Dodaj punkt"
      negativeButtonText="Anuluj"
      title="Dodać punkt?"
      :positiveAction="() => addAction()"
    >
      <v-layout column slot="content">
        <div class="text-subtitle-1 black--text">
          Nowe pole w karcie pacjenta utworzy się na stałe
          <u>u wszystkich osób</u>, które mają aktualnie otwarte leczenie. Jeśli
          usuniesz pole, to karta pacjenta zaktualizuje się dopiero wtedy, kiedy
          zakończysz leczenie i otworzysz nowe.
        </div>
      </v-layout>
    </ConfirmDialog>
    <ConfirmDialog
      v-if="showAltAddDialog"
      :value.sync="showAltAddDialog"
      positiveButtonText="Dodaj podpunkt"
      negativeButtonText="Anuluj"
      title="Dodać podpunkt?"
      :positiveAction="() => addAction()"
    >
      <v-layout column slot="content">
        <div class="text-subtitle-1 black--text">
          Nowy podpunkt do pola w karcie pacjenta utworzy się na stałe 
          <u>u wszystkich osób</u>, które mają aktualnie otwarte leczenie. Jeśli
          usuniesz pole, to karta pacjenta zaktualizuje się dopiero wtedy, kiedy
          zakończysz leczenie i otworzysz nowe.
        </div>
      </v-layout>
    </ConfirmDialog>
  </div>
</template>

<script>
import SettingsService from "@/services/settings.service";
import ProxyCRUDMixin from "@/mixins/ProxyCRUDMixin";
import DoctorReloadMixin from "@/mixins/DoctorReloadMixin";
import { mapActions, mapGetters } from "vuex";

import URO_NAMES from "@/constants/patientcard/uro";
import PHY_NAMES from "@/constants/patientcard/physio";
import PED_NAMES from "@/constants/patientcard/pediatric";

export default {
  mixins: [ProxyCRUDMixin, DoctorReloadMixin],
  props: {
    cardType: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      showAddDialog: false,
      showAltAddDialog: false,
      showEditDialog: false,
      indexToEdit: undefined,
      toEdit: undefined,
      nameToEdit: undefined,
      showDeleteDialog: false,
      nameToDelete: undefined,
      name: "",
      subname: "",
      below: "",
      fields: [],
      addAction: undefined,
      cardTypeVisibleProxy: false,
    };
  },
  components: {
    DefaultButton: () => import("@/components/buttons/DefaultButton"),
    LabelTextField: () => import("@/components/LabelTextField"),
    DefaultSelect: () => import("@/components/selects/DefaultSelect"),
    DefaultSubtitle: () => import("@/components/text/DefaultSubtitle"),
    PatientCardSettingsDelegate: () =>
      import("@/components/delegates/PatientCardSettingsDelegate"),
    ConfirmDialog: () => import("@/components/popups/ConfirmDialog"),
    MaxlengthErrorMessage: () =>
      import("@/components/controls/MaxlengthErrorMessage"),
  },
  computed: {
    ...mapGetters("ui", ["visibleCardTypes"]),
    items() {
      const list = [];
      this.fields.forEach((element) => {
        if (!element.bullet) {
          list.push(element);
        }
      });
      return list;
    },
    editName() {
      return "Edytuj " + `"${this.nameToEdit}"`;
    },
    cardTypeVisible: {
      get() {
        return this.visibleCardTypes.includes(this.cardType);
      },
      set(value) {
        if (value) {
          if (this.visibleCardTypes.indexOf(this.cardType) === -1) {
            this.updateVisibleCardTypes([
              ...this.visibleCardTypes,
              this.cardType,
            ]);
          }
        } else {
          const index = this.visibleCardTypes.indexOf(this.cardType);
          if (index !== -1) {
            let tmp = [...this.visibleCardTypes];
            tmp.splice(index, 1);
            this.updateVisibleCardTypes(tmp);
          }
        }
      },
    },
  },
  methods: {
    ...mapActions("ui", ["updateVisibleCardTypes"]),
    fetch() {
      this.beforeRequest();
      SettingsService.getPatientCardSettings(this.cardType)
        .then((x) => {
          this.fields = x.appointment_history_fields;
          this.cardTypeVisibleProxy = x.visible;
          this.cardTypeVisible = x.visible;
          this.reset();
        })
        .catch((error) => {
          if (error.response && error.response.status == 403) {
            this.handleError("Brak dostępu.");
          } else {
            this.handleError("Coś poszło nie tak.");
          }
        });
    },
    add() {
      if (this.name.length == 0) {
        this.handleError("Nazwa pola nie może być pusta.");
        return;
      }
      this.beforeRequest();
      SettingsService.addFieldToPatientCardSettings(
        {
          name: this.name,
          bullet: false,
        },
        this.cardType
      )
        .then((x) => {
          this.name = "";
          this.fields = x.results;
          this.handleSuccess("Pomyślnie dodano nowe pole.");
        })
        .catch(() => {
          this.handleError("Nie udało się dodać nowego pola.");
        });
    },
    addWithBullet() {
      if (this.subname.length == 0) {
        this.handleError("Nazwa pola nie może być pusta.");
        return;
      }
      if (this.below.length == 0) {
        this.handleError("Wybierz nazwe pola.");
        return;
      }
      this.beforeRequest();
      SettingsService.addFieldToPatientCardSettings(
        {
          name: this.subname,
          bullet: true,
          below: this.below,
        },
        this.cardType
      )
        .then((x) => {
          this.subname = "";
          this.below = "";
          this.fields = x.results;
          this.handleSuccess("Pomyślnie dodano nowe pole.");
        })
        .catch(() => {
          this.handleError("Nie udało się dodać nowego pola.");
        });
    },
    deleteDialog(name) {
      this.nameToDelete = name;
      this.showDeleteDialog = true;
    },
    remove(name) {
      this.beforeRequest();
      SettingsService.removeFieldToPatientCardSettings(
        { name: name },
        this.cardType
      )
        .then((x) => {
          this.fields = x.results;
          this.handleSuccess("Wpis został usunięty");
        })
        .catch(() => {
          this.handleError("Nie udało się usunąć pola.");
        });
    },
    editDialog(field, index) {
      this.toEdit = {...field};
      this.indexToEdit = index;
      this.nameToEdit = this.label(field);
      this.showEditDialog = true;
    },
    onSave() {
      this.fields[this.indexToEdit].label = this.toEdit.label;
      this.save();
    },
    save() {
      this.beforeRequest();
      SettingsService.updatePatientCardSettings({
        card_type: this.cardType,
        appointment_history_fields: this.fields,
        visible: this.cardTypeVisibleProxy,
      })
        .then((x) => {
          this.fields = x.appointment_history_fields;
          this.cardTypeVisibleProxy = x.visible;
          this.cardTypeVisible = x.visible;
          this.handleSuccess("Zmiany zostały zapisane");
        })
        .catch(() => {
          this.handleError("Nie udało się zapisać zmian.");
        });
    },
    addDialog(action) {
      this.addAction = action;
      this.showAddDialog = true;
    },
    addAltDialog(action) {
      this.addAction = action;
      this.showAltAddDialog = true;
    },
    array_move(arr, old_index, new_index) {
      if (new_index >= arr.length) {
        var k = new_index - arr.length + 1;
        while (k--) {
          arr.push(undefined);
        }
      }
      arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    },
    moveUp(index) {
      this.array_move(this.fields, index, index - 1);
    },
    moveDown(index) {
      this.array_move(this.fields, index, index + 1);
    },
    reloadData() {
      this.fetch();
    },
    label(field) {
      if (this.cardType == 0) {
        return PHY_NAMES[field.name] || field.label;
      } else if (this.cardType == 1) {
        return URO_NAMES[field.name] || field.label;
      } else {
        return PED_NAMES[field.name] || field.label;
      }
    },
  },
  mounted() {
    this.fetch();
  },
};
</script>

<style lang="scss" scoped>
.zg-text-control::v-deep label {
  color: rgba(0, 0, 0, 0.87);
}
</style>