<template>
  <DefaultButton
    :block="block"
    :class="['pb-0 mb-0 mt-0 mx-0 px-0', block && 'block']"
    :loading="loading"
    @click.prevent="() => $emit('clicked')"
    >{{ label }}
  </DefaultButton>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: "Szablony",
    },
    loading: {
      type: Boolean,
      default: false,
    },
    block: {
      type: Boolean,
      default: false
    }
  },
  components: {
    DefaultButton: () => import("@/components/buttons/DefaultButton"),
  },
};
</script>

<style scoped>
.block {
  width: 100%;
}
</style>