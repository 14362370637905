<template>
  <v-form>
    <v-layout column>
      <v-divider class="ma-4"></v-divider>
      <v-layout align-center class="mx-4 mb-4">
        <DefaultSubtitle color="primary"
          >Miejsca udzielania świadczeń</DefaultSubtitle
        >
        <InfoButton
          text="Poniższe dane wypełnia właściciel podmiotu leczniczego lub właściciel praktyki. Nie wypełniają pracownicy podmiotu leczniczego."
        ></InfoButton>
      </v-layout>
      <v-layout column class="mb-4">
        <v-flex shrink class="mx-4">
          <DefaultButton
            @click.prevent="() => showMedicalPlacePopup(!!medicalPlace.id)"
            bgColor="primary"
            class="my-1 mx-0"
          >
            {{ medicalPlace.id ? "Edytuj miejsce" : "Dodaj miejsce" }}
          </DefaultButton>
        </v-flex>
      </v-layout>
    </v-layout>
    <P1MedicalPlacePopup
      v-if="medicalPlacePopupVisible"
      :edit="edit"
      :value.sync="medicalPlacePopupVisible"
      :medicalPlace.sync="medicalPlace"
      @success="fetch"
      @error="handleError"
    ></P1MedicalPlacePopup>
    <ConfirmDialog
      title="Usuń miejsce"
      v-if="deleteMedicalPlacePopupVisible"
      :value.sync="deleteMedicalPlacePopupVisible"
      text="Czy na pewno chcesz usunąć wybrane miejsce?"
      :positiveAction="deleteMedicalPlace"
    ></ConfirmDialog>
  </v-form>
</template>

<script>
import P1Service from "@/services/p1.service";
import ProxyCRUDMixin from "@/mixins/ProxyCRUDMixin";

export default {
  mixins: [ProxyCRUDMixin],
  props: {
    p1Data: {},
  },
  data() {
    return {
      edit: false,
      medicalPlace: {},
      medicalPlacePopupVisible: false,
      deleteMedicalPlacePopupVisible: false,
    };
  },
  components: {
    DefaultButton: () => import("@/components/buttons/DefaultButton"),
    P1MedicalPlacePopup: () =>
      import("@/components/popups/p1/P1MedicalPlacePopup"),
    ConfirmDialog: () => import("@/components/popups/ConfirmDialog"),
    InfoButton: () => import("@/components/buttons/InfoButton"),
    DefaultSubtitle: () => import("@/components/text/DefaultSubtitle"),
    P1MedicalPlaceTable: () =>
      import("@/components/tables/p1/P1MedicalPlaceTable"),
  },
  computed: {
    hasMedicalPlace() {
      return !!this.medicalPlace.id;
    },
  },
  methods: {
    fetch(message) {
      this.beforeRequest();
      P1Service.getMedicalPlaces()
        .then((reply) => {
          console.log("REP", reply);
          this.medicalPlace = reply;
          if (message) {
            this.handleSuccess(message);
          } else {
            this.reset();
          }
        })
        .catch((error) => {
          console.log("ERROR", error, error.response);
          this.handleError("Coś poszło nie tak.");
        });
    },
    showMedicalPlacePopup(edit) {
      this.edit = edit;
      if (!this.edit) {
        this.medicalPlace = {};
        this.medicalPlace.rpwdl = this.p1Data.rpwdl;
        this.medicalPlace.regon = this.p1Data.regon;
      }
      this.medicalPlacePopupVisible = true;
    },
    showDeleteMedicalPlacePopup(medicalPlace) {
      this.medicalPlace = medicalPlace;
      this.deleteMedicalPlacePopupVisible = true;
    },
    deleteMedicalPlace() {
      this.beforeRequest();
      P1Service.deleteMedicalPlace(this.medicalPlace)
        .then(() => {
          this.fetch("Miejsce zostało usunięte.");
        })
        .catch(() => {
          this.handleError("Nie udało się usunąć miejsca.");
        });
    },
  },
  mounted() {
    this.fetch();
  },
};
</script>