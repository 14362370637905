<template>
  <div>
    <TemplateButton
      @clicked="handleClicked"
      :loading="loading"
      :block="block"
    ></TemplateButton>
    <TemplatePopup
      v-if="templatePopupVisible"
      :value.sync="templatePopupVisible"
      :templates="templates"
      @selected="handleSelected"
    ></TemplatePopup>
  </div>
</template>

<script>
import CRUDMixin from "@/mixins/CRUDMixin";

export default {
  mixins: [CRUDMixin],
  props: {
    fetcher: {},
    block: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      templatePopupVisible: false,
      templates: [],
    };
  },
  components: {
    TemplateButton: () => import("@/components/buttons/TemplateButton"),
    TemplatePopup: () => import("@/components/popups/TemplatePopup"),
  },
  methods: {
    handleSelected(template) {
      this.$emit("selected", template);
    },
    handleClicked() {
      this.fetch().then(() => {
        this.templatePopupVisible = true;
      });
    },
    fetch() {
      this.beforeRequest();
      return new Promise((resolve, reject) => {
        this.fetcher()
          .then((reply) => {
            this.templates = reply;
            this.reset();
            resolve();
          })
          .catch((error) => {
            console.log("ERROR", error, error.response);
            this.handleError("Coś poszło nie tak.");
            reject();
          });
      });
    },
  },
};
</script>