<template>
  <v-layout column>
    <v-layout>
      <div class="text-subtitle-1 black--text mb-1 ml-4">{{ label }}</div>
      <slot name="header-items"></slot>
    </v-layout>
    <v-scroll-y-transition>
      <v-textarea
        dense
        v-if="!collapsed"
        :placeholder="placeholder"
        outlined
        required
        :rules="rules"
        v-model="text"
        :class="[selected ? 'zg-slta' : 'default-input-style', 'mx-4']"
        :disabled="disabled"
        :readonly="readOnly"
        :rows="3"
        :hide-details="hideDetails"
        auto-grow
      >
        <template v-if="autosave" slot="append">
          <AutosaveIcon :value="status"></AutosaveIcon>
        </template>
      </v-textarea>
    </v-scroll-y-transition>
    <slot name="footer-items"></slot>
  </v-layout>
</template>

<script>
import ModificationMixin from "@/mixins/ModificationMixin";

export default {
  mixins: [ModificationMixin],
  props: {
    value: {},
    label: {},
    placeholder: {},
    disabled: {
      default: false
    },
    rules: {},
    readOnly: {},
    collapsed: {
      default: false
    },
    hideDetails: {
      default: false
    },
    selected: {
      default: false
    }
  },
  components: {
    AutosaveIcon: () => import("../AutosaveIcon")
  },
  computed: {
    text: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("update:value", value);
      }
    }
  },
  mounted() {
    if (this.autosave) {
      this.startWatching("text");
    }
  }
};
</script>

<style class="scss">
.zg-slta.v-input .v-input__slot {
  border: 1px solid #1BAAD6;
  border-radius: 15px !important;
  -webkit-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1) !important;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1) !important;
}
</style>