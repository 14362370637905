<template>
  <v-layout column>
    <v-layout column class="mt-4">
      <v-layout column class="mx-3 zg-cure-form">
        <v-layout v-if="!isSpectatorMode" wrap align-center class="mb-2">
          <DefaultSubtitle class="ml-4">Przebieg leczenia</DefaultSubtitle>
          <v-spacer></v-spacer>
          <DefaultButton
            class="mr-4 ml-2"
            color="white"
            :bg-color="
              !session.active && !isSessionTypeActive ? 'primary' : 'primary'
            "
            :primary="!session.active && !isSessionTypeActive"
            :loading="loading"
            @click.stop="
              session.active
                ? onSessionChange(session.id, 0)
                : onSessionChange(session.id, 1)
            "
            >{{
              session.active
                ? "Zakończ leczenie"
                : isSessionTypeActive
                ? "Przywróć leczenie"
                : "Rozpocznij leczenie"
            }}</DefaultButton
          >
        </v-layout>
        <LabelTextField
          label="Nazwa leczenia"
          placeholder="Wpisz nazwę..."
          :disabled="!session.active || isSpectatorMode"
          :value.sync="session.name"
          :rules="[...textRules, maxlengthRule]"
          :required="true"
          :autosave="session.active"
          :syncId="syncId"
        ></LabelTextField>
        <LabelTextField
          label="Osoba kierująca na leczenie (imię, nazwisko, numer prawa do wykonywania zawodu)"
          placeholder="Wpisz osobę kierującą na leczenie.."
          :disabled="!session.active || isSpectatorMode"
          :value.sync="session.referring_person"
          :autosave="session.active"
          :syncId="syncId"
          :rules="[maxlengthRule]"
        >
          <InfoButton
            slot="header-items"
            title="Osoba kierująca na leczenie"
            text="Jeśli pacjent posiada skierowanie, w tym polu wpisz imię i nazwisko oraz numer prawa do wykonywania zawodu osoby, która wystawiła skierowanie."
            buttonText="Rozumiem"
          ></InfoButton>
        </LabelTextField>
      </v-layout>
      <v-layout column class="zg-single-note-card">
        <v-layout style="width: 100%">
          <SubviewCard
            :class="!$vuetify.breakpoint.smAndDown && 'mx-6 mt-3'"
            style="width: 100%"
            :padding="false"
          >
            <v-layout column>
              <v-layout
                v-if="session.active && !isSpectatorMode"
                class="align-self-end mx-3 mt-6"
              >
                <v-layout wrap>
                  <FileUploadForm
                    buttonTitle="Dodaj zdjęcie lub plik"
                    @uploaded="onUploaded"
                    small
                  ></FileUploadForm>
                  <v-layout column v-if="!hideCloneShfs">
                    <DefaultButton
                      :class="[
                        $vuetify.breakpoint.xs ? 'mx-4 my-2' : 'ma-0 mr-3',
                      ]"
                      color="white"
                      bgColor="primary"
                      :loading="loading"
                      small
                      @click.prevent="onCloneShfs"
                    >
                      Powiel z poprzedniego leczenia
                    </DefaultButton>
                  </v-layout>
                </v-layout>
              </v-layout>
              <slot name="shfs"></slot>
            </v-layout>
          </SubviewCard>
        </v-layout>
      </v-layout>
    </v-layout>
    <v-layout
      full-width
      justify-center
      align-center
      :class="[$vuetify.breakpoint.smAndDown && 'column', 'mt-6']"
      v-if="session.active && session.card && !isSpectatorMode"
    >
      <div
        v-if="$vuetify.breakpoint.mdAndUp && hadAppointmentBefore"
        style="width: 285px; heigth: 36px"
        class="mx-7"
      ></div>
      <v-spacer v-show="$vuetify.breakpoint.mdAndUp"></v-spacer>
      <CardInfoLabel :value="session">
      </CardInfoLabel>
      <v-spacer v-show="$vuetify.breakpoint.mdAndUp"></v-spacer>
      <DefaultButton
        v-if="hadAppointmentBefore"
        :class="'mx-7'"
        color="white"
        bgColor="primary"
        :loading="loading"
        @click.prevent="onCloneAhfs"
      >
        Wklej wpisy z poprzedniej wizyty
      </DefaultButton>
    </v-layout>
    <v-layout
      v-if="session.active && session.card && !isSpectatorMode"
      column
      align-center
      justify-center
      :class="['mx-4', isHomeAppointment ? '' : 'mb-4']"
    >
      <v-switch
        label="Wizyta domowa"
        color="expressive_green"
        class="zg-text-control"
        hide-details
        v-model="isHomeAppointment"
        :ripple="false"
        inset
      ></v-switch>
      <LabelTextField
        class="zg-html-text-area zg-address-appointment mt-6"
        style="width: 400px"
        v-if="isHomeAppointment"
        label="Adres domowej wizyty"
        :value.sync="session.card.home_appointment_address"
        :autosave="session.active"
        :syncId="syncId"
      ></LabelTextField>
    </v-layout>
    <slot name="ahfs"></slot>
  </v-layout>
</template>

<script>
import Validators from "@/mixins/Validators";

const SHFS_FIELDS = 0;
const AHFS_FIELDS = 1;

export default {
  mixins: [Validators],
  props: {
    session: {},
    owner: {},
    patient: {},
    hideCloneShfs: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    isSessionTypeActive: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    isHomeAppointment() {
      if (
        this.isHomeAppointment &&
        !this.session.card.home_appointment_address &&
        this.patient &&
        this.patient.address
      ) {
        this.$set(
          this.session.card,
          "home_appointment_address",
          this.patient.address
        );
      } else if (this.session.card && !this.isHomeAppointment) {
        this.$set(
          this.session.card,
          "home_appointment_address",
          ""
        );
      }
    },
  },
  computed: {
    hadAppointmentBefore() {
      const fields = Object.values(this.session.ahfs);
      for (let index = 0; index < fields.length; index++) {
        const field = fields[index];
        if (field.length >= 2) {
          return true;
        }
      }
      return false;
    },
    isSpectatorMode() {
      return !!this.owner;
    },
    isHomeAppointment: {
      get() {
        return (
          this.session.card &&
          this.session.card.home_appointment
        );
      },
      set(value) {
        if (this.session.card) {
          this.$set(this.session.card, "home_appointment", value);
        }
      },
    },
  },
  components: {
    LabelTextField: () => import("@/components/LabelTextField"),
    DefaultButton: () => import("@/components/buttons/DefaultButton"),
    InfoButton: () => import("@/components/buttons/InfoButton"),
    FileUploadForm: () => import("@/components/FileUploadForm"),
    SubviewCard: () => import("@/components/cards/SubviewCard"),
    DefaultSubtitle: () => import("@/components/text/DefaultSubtitle"),
    CardInfoLabel: () =>
      import("@/components/text/CardInfoLabel"),
  },
  methods: {
    onCloneShfs() {
      this.$emit("clone", SHFS_FIELDS);
    },
    onCloneAhfs() {
      this.$emit("clone", AHFS_FIELDS);
    },
    onSessionChange(sessionId, mode) {
      this.$emit("change", { sessionId, mode });
    },
    onNew() {
      this.$emit("new");
    },
    onUploaded(result) {
      this.$emit("uploaded", result);
    },
    maxlengthRule(text) {
      return (
        text.length < 255 ||
        `Maksymalna ilość znaków to 255, aktualnie masz ${text.length}.`
      );
    },
  },
};
</script>

<style scoped>
.zg-text-control::v-deep label {
  color: rgba(0, 0, 0, 0.87);
}
</style>