<template>
  <v-layout column>
    <Autocomplete
      :value.sync="internalValue"
      label="Rola pracownika medycznego"
      :data="items"
      :clearAfterSelection="false"
      :returnObject="false"
      :disabled="disabled"
      :required="required"
      placeholder="Wybierz rolę..."
    >
      <InfoButton
        v-if="infoText"
        :text="infoText"
        :title="infoTitle"
        :buttonText="infoButtonText"
        slot="label-icon"
      ></InfoButton>
    </Autocomplete>
  </v-layout>
</template>

<script>
export default {
  props: {
    value: {},
    disabled: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    infoText: {
      type: String,
    },
    infoTitle: {
      type: String,
    },
    infoButtonText: {
      type: String
    }
  },
  data() {
    return {
      items: [
        { value: "FIZJO", text: "Fizjoterapeuta" },
        { value: "ASYS", text: "Asystent Medyczny" },
        { value: "PROF", text: "Inny Profesjonalista Medyczny" },
        { value: "LEK", text: "Lekarz" },
        { value: "LEKD", text: "Lekarz Dentysta" },
        { value: "FEL", text: "Felczer" },
        { value: "PIEL", text: "Pielęgniarka" },
        { value: "POL", text: "Położna" },
        { value: "FARM", text: "Farmaceuta" },
        { value: "RAT", text: "Ratownik Medyczny" },
        { value: "PADM", text: "Pracownik Administracyjny" },
        { value: "DIAG", text: "Diagnosta Labolatoryjny" },
        { value: "HIGSZKOL", text: "Higienistka Szkolna" },
      ],
    };
  },
  components: {
    Autocomplete: () => import("@/components/Autocomplete"),
    InfoButton: () => import("@/components/buttons/InfoButton"),
  },
  computed: {
    internalValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("update:value", value);
      },
    },
  },
};
</script>