<template>
  <v-layout column>
    <DefaultSubtitle class="mt-4 mx-4">Wybierz pracowników medycznch</DefaultSubtitle>
    <DefaultSubText class="mx-4 my-2">Wyświetlani są wyłącznie pracownicy, którzy przynajmniej raz zalogowali się do Booksy.</DefaultSubText>
    <BooksyResourceDelegate
      v-for="(resource, index) in resources"
      :key="resource.id"
      :value="resource"
      :isLast="index == resources.length - 1"
      :loading="loading"
      @update="onUpdate"
    ></BooksyResourceDelegate>
  </v-layout>
</template>

<script>
import ProxyCRUDMixin from "@/mixins/ProxyCRUDMixin";
import ResourcesService from "@/services/resources.service";

export default {
  mixins: [ProxyCRUDMixin],
  data() {
    return {
      resources: [],
    };
  },
  components: {
    BooksyResourceDelegate: () =>
      import("@/components/delegates/BooksyResourceDelegate"),
    DefaultSubtitle: () => import("@/components/text/DefaultSubtitle"),
    DefaultSubText: () => import("@/components/text/DefaultSubText")
  },
  methods: {
    fetch() {
      this.beforeRequest();
      ResourcesService.getResources()
        .then((reply) => {
          this.resources = reply;
          this.reset();
        })
        .catch((error) => {
          console.log("ERROR", error);
          this.handleError("Coś poszło nie tak");
        });
    },
    onUpdate(resource) {
      this.beforeRequest();
      ResourcesService.updateResource(resource)
        .then(() => {
          this.handleSuccess("Informacje o pracowniku zostały zaktualizowane");
        })
        .catch((error) => {
          console.log("ERROR", error);
          this.handleError("Coś poszło nie tak");
        });
    },
  },
  mounted() {
    this.fetch();
  },
};
</script>