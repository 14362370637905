<template>
  <v-layout v-if="show" class="mt-6 mb-3 mx-3" justify-center align-center>
    <Message
      class="mx-3"
      warning
      :type="1"
      text="Aby rozpocząć terapię, potwierdź zgody pacjenta w zakładce Informacje i zgody."
      @close="hide"
    >

    <InfoButton
      class="ml-2"
      noMargin
      slot="icon"
      text="Zalecamy aby zgoda na świadczenie zdrowotne była na piśmie dla celów dowodowych.<br>Natomiast kiedy zabiegowi lub świadczeniu zdrowotnemu towarzyszy podwyższone ryzyko dla pacjenta zgodę zawsze pobierz na piśmie.
        <br>Zgody na świadczenie zdrowotne samodzielnie udziela pacjent, który ukończył 16 lat, natomiast w przypadku osób ubezwłasnowolnionych lub niezdolnych do świadomego wyrażenia zgody zgody tej udziela przedstawiciel prawny."
    ></InfoButton>
    </Message>
  </v-layout>
</template>

<script>
export default {
  props: {
    patient: {},
  },
  data() {
    return {
      show: false,
    };
  },
  watch: {
    patient() {
      this.reload();
    },
  },
  components: {
    InfoButton: () => import("@/components/buttons/InfoButton"),
    Message: () => import("@/components/Message"),
  },
  methods: {
    hide() {
      this.show = false;
    },
    reload() {
      this.show = this.patient && !this.patient.health_benefits;
    },
  },
  mounted() {
    this.reload();
  },
};
</script>